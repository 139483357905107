<template>
  <div>
    <headTop></headTop>
    <!--帮助中心头部搜索-->
    <div class="help-search-head-box">
      <div class="help-search-head">
        <div class="logo">
          <img :src="logoUrl" width="128" height="22" :alt="$t('key1000010')" @click="gotoHome">
          <span class="line"></span>
          <span class="text" @click="gotoHelp">
            {{ $t('key1000036') }}
          </span>
        </div>
        <div class="search-ipt">
          <input class="search-input" v-model.trim="searchValue" @keyup.enter="getSearchDosc" :placeholder="$t('key1005557')"/>
          <button class="search-btn" @click="getSearchDosc">
            <span class="text">{{ $t('key1000012') }}</span>
          </button>
          <Icon type="md-close-circle" v-if="searchValue" @click="searchValue =''" class="search-ipt-close"/>
        </div>
        <div class="help-tabs">
          <div v-for="(item) in tabs" @click="tabChange(item)" class="item" :class="{active:tabIndex === item.value}">
            <span>{{ item.label }}</span>
            <span class="line"></span>
          </div>
        </div>
      </div>
    </div>
    <!--帮助中心内容-->
    <div class="help-content-box" v-if="tabIndex === 1">
      <template v-for="item in documentDataCopy">
        <div v-if="item._type === 'video'" class="help-content video-content">
          <div class="title">{{ $t('key1005558') }} <span class="more" @click="tabIndex=3">{{ $t('key1000019') }} ></span></div>

          <div class="video-box">
            <div>
              <div class="m-col-item video-item-0" @click="gotoOpenPage(1)">
                <p class="title">{{ $t('key1005559') }}</p>
                <p class="desc">{{ $t('key1005560') }}</p>
                <img class="play" src="../../../../assets/images/help/icon-play.png"/>
                <img class="play-h" src="../../../../assets/images/help/icon-play-h.png"/>
              </div>
              <div class="bottom-desc">{{ $t('key1005561') }}</div>
            </div>
            <div>
              <div class="m-col-item video-item-1" @click="gotoOpenPage(6)">
                <p class="title">{{ $t('key1005559') }}</p>
                <p class="desc">{{ $t('key1005480') }}</p>
                <img class="play" src="../../../../assets/images/help/icon-play.png"/>
                <img class="play-h" src="../../../../assets/images/help/icon-play-h.png"/>
              </div>
              <div class="bottom-desc">{{ $t('key1005562') }}</div>
            </div>
            <div>
              <div class="m-col-item video-item-2" @click="gotoOpenPage(2)">
                <p class="title">{{ $t('key1005559') }}</p>
                <p class="desc">{{ $t('key1005563') }}</p>
                <img class="play" src="../../../../assets/images/help/icon-play.png"/>
                <img class="play-h" src="../../../../assets/images/help/icon-play-h.png"/>
              </div>
              <div class="bottom-desc">{{ $t('key1005563') }}</div>
            </div>
            <div>
              <div class="m-col-item video-item-3" @click="gotoOpenPage(4)">
                <p class="title">{{ $t('key1005559') }}</p>
                <p class="desc">{{ $t('key1004614') }}</p>
                <img class="play" src="../../../../assets/images/help/icon-play.png"/>
                <img class="play-h" src="../../../../assets/images/help/icon-play-h.png"/>
              </div>
              <div class="bottom-desc">{{ $t('key1004614') }}</div>
            </div>
          </div>
        </div>
        <div v-else class="help-content">
          <div class="title">{{ item.name }}</div>
          <Row>
            <Col span="8" class="col-item" v-for="article in item.children">
              <p class="tit">{{ article.name }}</p>
              <template v-if="article.helpDocsList">
                <template v-for="(topArticle,index) in article.helpDocsList">
                  <p v-if="index<2" class="item" :title="topArticle.title"
                    @click="gotoHelpDocs(topArticle)">{{ topArticle.title }}</p>
                </template>
              </template>
            </Col>
          </Row>
        </div>
      </template>

      <div class="help-content self-content">
        <div class="title">{{ $t('key1005564') }}</div>
        <Row>
          <Col span="8" class="col-item">
            <img :src="icon03" width="40"/>
            <div class="z-box">
              <p class="tit pointer" @click="gotoPage('/modifyPassword')">{{ $t('key1004846') }}</p>
              <p class="desc">{{ $t('key1005565') }}</p>
            </div>
          </Col>
          <Col span="8" class="col-item">
            <img :src="icon01" width="40"/>
            <div class="z-box">
              <p class="tit pointer" @click="gotoPage('/modifyMobile')">{{ $t('key1005566') }}</p>
              <p class="desc">{{ $t('key1005567') }}</p>
            </div>
          </Col>
          <Col span="8" class="col-item">
            <img :src="icon02" width="40"/>
            <div class="z-box">
              <p class="tit pointer" @click="gotoPage('/modifyMailbox')">{{ $t('key1004589') }}</p>
              <p class="desc">{{ $t('key1005568') }}</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <!--问题汇总-->
    <div class="problem-summary-box" v-if="tabIndex === 0">
      <div class="problem-summary-box-content">
        <!--左边的文章菜单栏-->
        <div class="left-box">
          <p class="title">{{ $t('key1002337') }}</p>
          <div class="problem-list" v-for="item in documentData">
            <p class="sub-title" @click="item.show = !item.show">
              <span class="text" :title="item.name">{{ item.name }}</span>
              <Icon :class="{rotate:item.show}" type="ios-arrow-down icon"/>
            </p>
            <ul class="problem-list" v-if="item.show">
              <li v-for="sub in item.children">
                <p class="sub-title" @click="sub.show = !sub.show">
                  <span class="text second-menu" :title="sub.name">{{ sub.name }}</span>
                  <Icon :class="{rotate:sub.show}" type="ios-arrow-down icon"/>
                </p>
                <ul v-if="sub.show && sub.helpDocsList">
                  <li v-for="article in sub.helpDocsList" class="sub-title article"
                    :class="{active: activeId === article.id}">
                    <span class="three-menu" :data-id="article.id" :title="article.title"
                      @click="setArticle(article)">{{ article.title }}</span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <!--文章内容-->
        <div class="right-box">
          <div class="copy_box" @click="copyBtn">{{ $t('key1000000') }}</div>
          <div class="right-nav" v-if="breadMenu && breadMenu.length > 0">
            <span v-for="(item,index) in breadMenu">
              <span class="p-tit" :class="{'c-tit' : index === breadMenu.length -1}">{{ item }}</span>
              <span class="separate" v-if="index !== breadMenu.length -1">/</span>
            </span>
          </div>
          <p class="content-title">{{ contentTitle }}</p>
          <div class="right-content" v-viewer="viewerOptions" ref="scrollContainer" v-html="content"></div>
        </div>
        <!--右侧的锚点导航-->
        <anchorNavigation ref="anchorNav" setTop="145px" :headerHeight="65"></anchorNavigation>
      </div>
    </div>
    <!--搜索结果页-->
    <div class="search-result-box" v-if="tabIndex === 2">
      <div class="search-result">
        <p>{{ $t('key1005569') }}<span class="search-text">{{ searchValueText }}</span>{{ $t('key1005570') }}{{ searchHelpDoscList.length }}{{ $t('key1000850') }}</p>
        <ul v-if="searchHelpDoscList && searchHelpDoscList.length" class="search-list">
          <li v-for="item in searchHelpDoscList" class="search-list-item" @click="gotoHelpDocs(item)">{{ item.title }}
          </li>
        </ul>
        <div v-else style="height: 50px;padding: 50px 0;">{{ $t('key1005571') }}</div>
      </div>
    </div>
    <!--更多视频-->
    <helpVideoMore v-if="tabIndex === 3"></helpVideoMore>
    <!--底部-->
    <shopFooter></shopFooter>
  </div>
</template>

<script>
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import headTop from '@/components/layout/shopping/common/headTop';
import shopFooter from '@/components/layout/shopping/footer';
import helpVideoMore from '@/views/shopping/index/components/helpVideoMore';
import api from '@/api/';
import {videos} from '@/utils/enum';
import anchorNavigation from "@/components/common/anchorNavigation";
import {commonCopyContent} from "@/utils/common";

export default {
  name: 'help',
  mixins: [Mixin],
  data() {
    return {
      logoUrl: require('@/assets/images/logo.png'),
      icon01: require('@/assets/images/help-icon-1.png'),
      icon02: require('@/assets/images/help-icon-2.png'),
      icon03: require('@/assets/images/help-icon-3.png'),
      tabIndex: 1,
      videos: videos,
      activeId: null,
      content: '',
      contentTitle: '',
      breadMenu: [],
      tabs: [
        {label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005572'), value: 1},
        {label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002337'), value: 0}
      ],
      documentData: [],
      documentDataCopy: [],
      helpDoscList: [],
      searchHelpDoscList: [],
      searchValue: '',
      searchCount: 0,
    };
  },
  created() {
    let queryId = this.$route.query.id;
    let type = this.$route.query.type;
    this.getDetils().then(() => {
      if (queryId) {
        this.$nextTick(() => {
          this.activeId = Number(queryId);
          this.helpDoscList.forEach(i => {
            if (i.id === Number(queryId)) {
              this.setArticle(i);
              this.tabIndex = 0;
            }
          });
        });
      } else if (type === 'personalInfo') {
        this.seletDefaultArticle(this.documentData);
      }
    });
  },
  methods: {
    gotoOpenPage(id) {
      let origin = window.location.origin;
      if (origin.includes('localhost')) {
        window.open(origin + '/vedio.html?id=' + id, '_blank');
      } else {
        window.open(origin + '/yms-shopping-service/vedio.html?id=' + id, '_blank');
      }
    },
    gotoHome() {
      this.$router.push('/index');
    },
    gotoHelp() {
      this.tabIndex = 1;
      this.$router.push('/help');
    },
    gotoPage(path) {
      window.location.href = window.location.origin + '/yms-distributor-service/distributor.html#' + path;
    },
    // 搜索
    getSearchDosc() {
      let v = this;
      v.activeId = null;
      if (!v.searchValue) {
        v.tabIndex = 2;
        v.searchValueText = v.searchValue;
        v.searchHelpDoscList = [];
        return;
      }
      v.searchValueText = v.searchValue;
      const searchValueLower = v.searchValue.toLowerCase() || ''; // 将搜索关键字转换为小写
      v.searchHelpDoscList = v.helpDoscList.filter(i => {
        const titleLower = i.title.toLowerCase() || '';
        const contentLower = i.content.toLowerCase() || '';
        return titleLower.includes(searchValueLower) || contentLower.includes(searchValueLower);
      });
      v.tabIndex = 2;
      v.scrollTopFun();
    },
    // 切换tab栏
    tabChange(item) {
      this.tabIndex = item.value;
      if (item.value === 0 && !this.activeId) {
        this.seletDefaultArticle(this.documentData);
      } else {
        this.activeId = null;
        this.$router.push('/help');
      }
    },
    gotoHelpDocs(item) {
      this.tabIndex = 0;
      this.setArticle(item);
      this.scrollTopFun();
    },
    // 默认选中第一篇文章
    seletDefaultArticle(data) {
      if (data && data.length > 0) {

        function treeToList(tree, result = []) {
          tree.forEach(node => {
            result.push(node);
            node.children && treeToList(node.children, result);
            node.helpDocsList && treeToList(node.helpDocsList, result);
          });
          return result;
        }

        let newData = this.uniqueFunc(treeToList(data), 'id');
        let list = newData.filter((item) => {
          if (!item.helpDocsList && !item.children) {
            return item;
          }
        });
        if (list && list.length > 0) {
          this.setArticle(list[0], true);
        }
      }
    },
    // 获取所有目录
    getDetils() {
      let v = this;

      function recursion(originData) {
        let obj = {};
        let p = 0;
        v.helpDoscList = [];
        originData.forEach(i => {
          let name = i.name;
          if (i.categoryInternational.length > 0) {
            i.categoryInternational.map((item) => {
              if (item.language === v.languagesVal) {
                name = item.name;
              }
            })
          } else {
            name = i.name;
          }
          i.children = [];
          i.show = true;
          i.fullName = [name];
          obj[i.id] = i;
          if (obj[i.pId]) {
            i.fullName.push(...obj[i.pId].fullName);
            if (i.helpDocsList) {
              i.helpDocsList = i.helpDocsList.filter(i => i.showStatus && !i.isDeleted);
              i.helpDocsList.forEach(d => d.fullName = i.fullName);
              v.helpDoscList.push(...i.helpDocsList);
              i.helpDocsList.map((ele) => {
                let helpDocInternationalList = ele.helpDocInternationalList || [];
                if (helpDocInternationalList.length > 0) {
                  helpDocInternationalList.map((talg) => {
                    if (talg.language === v.languagesVal) {
                      ele.title = talg.title;
                      ele.content = talg.content;
                    }
                  })
                }
              })
            }
            if (i.categoryInternational.length > 0) {
              i.categoryInternational.map((item) => {
                if (item.language === v.languagesVal) {
                  i.name = item.name;
                }
              })
            }
            obj[i.pId].children.push(i);
          }
          if (i.pId === 1) {
            p = i.id;
          }
        });
        return obj[p].children;
      }

      return new Promise(resolve => {
        v.axios.post(api.get_ymsHelpCategory_shopping_buildCategoryTree).then(response => {
          if (response.data.code === 0) {
            v.documentData = recursion(response.data.datas, 1);
            let data = recursion(response.data.datas, 1);
            if (data.length) {
              data.splice(1, 0, {
                _type: 'video'
              });
            }
            v.documentDataCopy = data;
            resolve();
          }
        });
      });
    },
    // 选择文章
    setArticle(article, value) {
      let v = this;
      if (value) {
        v.tabIndex = 0;
      }
      v.activeId = article.id;
      v.content = article.content;
      v.contentTitle = article.title;
      v.breadMenu = article.fullName.slice(0, -1).reverse();
      v.scrollTopFun();
      v.$router.push('/help?id=' + article.id);
      setTimeout(() => {
        v.$nextTick(() => {
          const content = v.$el.querySelector('.right-content');
          let dom = v.$refs['scrollContainer'];
          v.$refs['anchorNav'].getAnchorsFromContent(dom, content);
        }, 1000);
      })
    },
    // 记录浏览量
    recordViews(id) {
      let v = this;
      v.axios.get(api.get_ymsHelpDocs_shopping_recordShowQuantity + `?id=${id}`).then(response => {
        if (response.data.code === 0) {
        }
      });
    },
    // 复制
    copyBtn() {
      let href = window.location.href;
      let content = `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000001')}${this.contentTitle}\n` + `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000002')}${href}`;
      if (content) {
        commonCopyContent(content, aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000004'));
      }
    }
  },
  watch: {
    '$route'() {
      let queryId = this.$route.query.id;
      if (queryId) {
        this.$nextTick(() => {
          this.activeId = Number(queryId);
          this.helpDoscList.forEach(i => {
            if (i.id === Number(queryId)) {
              this.setArticle(i);
              this.tabIndex = 0;
              this.recordViews(i.id);
            }
          });
        });
      }
    }
  },
  components: {
    headTop,
    shopFooter,
    helpVideoMore,
    anchorNavigation
  },
};
</script>

<style>
.right-content img {
  max-width: 100%;
}

.right-content p span {
  word-wrap: break-word;
  word-break: break-all;
  text-wrap: initial !important;
}
</style>
<style scoped lang="less">
@import "src/assets/styles/shopping/common";

.img-content {
  width: 1200px;
  margin: 50px auto 0 auto;
  background-color: #ffffff;
  display: inline-block;
  overflow: hidden
}

.img-content .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  padding: 0px 18px;
  border-bottom: 1px solid #ddd;
  position: relative;
  background-color: #ffffff;
  z-index: 1;
}

.head-r {
  position: fixed;
  right: 18px;
  top: 50px;
  height: 48px;

}

.head-r .icon {
  font-size: 18px;
  margin-right: 5px;
  cursor: pointer;
  color: #666666;
}

.head-r .icon:hover {
  opacity: 0.9;
  color: #666666;
}

.head-r .i-close:hover {
  color: red;
}

.help-search-head-box {
  border-bottom: 1px solid #D7D7D7;
  position: sticky;
  top: 0px;
  background: #fff;
  z-index: 10;
}

.help-search-head {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  height: 64px;
  font-size: 16px;

  .logo {
    display: flex;
    align-items: center;
    width: 344px;
    cursor: pointer;

    .line {
      content: '';
      width: 1px;
      height: 14px;
      background-color: #aaaaaa;
      display: block;
      margin: 0 16px;
    }

    .text {
      font-size: 18px;
      color: #333333;
    }
  }

  .search-ipt {
    width: 560px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .search-input {
      width: 490px;
      height: 34px;
      border: 2px solid #FF5310;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
      text-indent: 5px;
      font-size: 12px;
    }

    .search-btn {
      font-size: 14px;
      width: 72px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      font-weight: 400;
      color: #FFFFFF;
      background-color: @subject_color;
      border: none;
      outline: none;
      cursor: pointer;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }

    .search-ipt-close {
      position: absolute;
      right: 78px;
      top: 24px;
      cursor: pointer;
    }

    .search-ipt-close:hover {
      opacity: 0.9;
    }
  }

  .help-tabs {
    width: 344px;
    display: flex;
    justify-content: flex-end;

    .line {
      display: none;
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: #FF5310;
      transition: 0.3s ease-out all;
    }

    .active {
      position: relative;

      .line {
        display: inline-block;
      }
    }

    .item {
      padding: 0 20px;
      height: 100%;
      line-height: 64px;
      cursor: pointer;
    }

    .item:hover, .active {
      color: @subject_color;
    }
  }
}

.help-content-box {
  background-color: #f2f2f2;
  padding: 25px 32px 5px 32px;

  .more {
    float: right;
    color: #000000;
    cursor: pointer;
    font-size: 13px;
    font-weight: initial;
    line-height: 27px;
  }

  .help-content {
    width: 1200px;
    margin: 0 auto 25px auto;
    padding: 24px 32px 0 32px;
    background-color: #ffffff;

    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
      color: #000000;
      font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
    }

    .tit {
      font-family: 'Arial Normal', 'Arial', sans-serif;
      font-size: 16px;
      color: #000000;
      margin-bottom: 5px;
    }

    .col-item {
      margin-bottom: 24px;
      vertical-align: top;

      img {
        vertical-align: top;
        margin-right: 10px;
      }

      .z-box {
        display: inline-block;
        width: 210px;
      }
    }

    .item {
      font-size: 12px;
      color: #555555;
      margin-bottom: 5px;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .item:hover {
      color: @subject_color;
    }
  }

  .self-content {
    margin-bottom: 20px;

    .pointer {
      cursor: pointer;
    }

    .pointer:hover {
      opacity: 0.9;
    }
  }
}

.problem-summary-box {
  background-color: #F2F2F2;
  padding-bottom: 60px;;

  .problem-summary-box-content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .left-box {
      width: 230px;
      background-color: #ffffff;

      .title {
        padding: 0 24px;
        height: 46px;
        line-height: 46px;
        font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 14px;
        text-align: left;
      }

      .problem-list {
        line-height: 46px;
        color: #333333;
        font-size: 14px;

        .sub-title {
          height: 46px;
          padding: 0 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .text {
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .icon {
            text-align: right;
            transition: 0.2s ease-in transform;
          }

          .rotate {
            transform: rotate(180deg);
          }
        }

        .active {
          color: #FF5310;
          background-color: #FFF5F1;
        }

        .sub-title:hover {
          color: #FF5310;
          background-color: #FFF5F1;
        }

        .item-box {
          .item {
            padding-left: 48px;
            height: 46px;
            cursor: pointer;
          }

          .item:hover, .active {
            color: #FF5310;
            background-color: #FFF5F1;
          }
        }
      }
    }

    .right-box {
      width: 960px;
      background-color: #ffffff;
      position: relative;

      .copy_box {
        position: absolute;
        top: 75px;
        right: 20px;
        z-index: 10;
        cursor: pointer;
      }

      .right-nav {
        height: 48px;
        line-height: 48px;
        background-color: #F2F2F2;

        .separate {
          padding: 0 5px;
        }

        .p-tit {
          color: #00000072;
        }

        .c-tit {
          color: #000000;
        }
      }

      .right-content {
        padding: 24px;
        overflow: hidden;
      }

      .content-title {
        font-size: 16px;
        color: #000;
        font-weight: bold;
        padding: 24px 24px 0 24px;
      }
    }
  }
}

.flex {
  display: flex;
}

.second-menu {
  padding-left: 14px;
}

.three-menu {
  padding-left: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.article {
  cursor: pointer;
}

.search-result-box {
  background-color: #F2F2F2;
  padding-bottom: 20px;
}

.search-result {
  width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 35px 40px 10px 40px;
  margin-bottom: 20px;

  .search-text {
    color: #FF5310;
  }

  .search-list {
    .search-list-item {
      padding: 15px 0;
      color: #000;
      font-size: 14px;
      cursor: pointer;
    }

    .search-list-item:hover {
      color: #FF5310;
    }
  }
}

.enlarge-box {
  text-align: center;

  img {
    max-width: 100%;
  }
}

.big-img-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  text-align: center;
  overflow: auto;

  .img-box {
    cursor: move;

    img {
      border: 1px solid #e3e5e8;
    }
  }

  a {

  }
}

.video-content {
  .video-box {
    display: flex;
    justify-content: space-between;

    .m-col-item {
      width: 260px;
      height: 150px;
      background-position: center center;
      background-size: contain;
      padding: 25px 0 0 28px;
      cursor: pointer;

      .play {
        display: block;
        width: 30px;
      }

      .play-h {
        display: none;
        transition: all 0.3s ease;
      }

      .title {
        font-size: 17px;
        color: #000;
        font-weight: initial;
        margin-bottom: 0px;
      }

      .desc {
        font-size: 22px;
        color: #000;
        font-weight: bold;
        margin-bottom: 15px;
      }

      &:hover {
        .play {
          display: none;
        }

        .play-h {
          display: block;
          transform: rotate(1.1);
          width: 30px;
        }
      }
    }

    .bottom-desc {
      width: 260px;
      text-align: center;
      margin: 15px 0 26px 0;
      color: #555555;
      font-size: 14px;
    }

    .video-item-0 {
      background-image: url("../../../../assets/images/help/b1.png");
    }

    .video-item-1 {
      background-image: url("../../../../assets/images/help/b2.png");
    }

    .video-item-2 {
      background-image: url("../../../../assets/images/help/b3.png");
    }

    .video-item-3 {
      background-image: url("../../../../assets/images/help/b4.png");
    }
  }
}
</style>
